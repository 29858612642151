
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'

import { ErrorMessage, Field, Form } from 'vee-validate'
import * as Yup from 'yup'
import { useStore } from 'vuex'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { Actions } from '@/store/enums/StoreEnums'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import SessionStorage from '@/core/services/SessionStorage'
import { buildApiResourceUrl } from '@/core/helpers/activity-template'
import { getTemplateImagesBySection } from '@/core/helpers/template/template'

export default defineComponent({
  name: 'LoginPublic',
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const submitButton = ref<HTMLButtonElement | null>(null)
    const mobileViewMode = computed(() => store.getters.getMobileViewMode)

    // Create form validation object
    const login = Yup.object().shape({
      name: Yup.string().required().label('Name')
    })
    // const hasGetUserMedia = () => {
    //   return !!(navigator.getUserMedia)
    // }

    // Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors
      // store.dispatch(Actions.ACTIVE_USER_LOGOUT)

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute('data-kt-indicator', 'on')
      }

      // values.uid = router.currentRoute.value.params.id
      values.publicSessionUuid = router.currentRoute.value.params.id
      console.log('values', values)
      // document.cookie.split(';').forEach(function (c) { document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/') })
      // Send login request
      await store.dispatch(Actions.API_GET_CSRF_TOKEN).then(() => {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value?.setAttribute('data-kt-indicator', 'on')
        if (mobileViewMode.value === true) {
          executeLogin(values)
        } else {
          navigator.mediaDevices.getUserMedia(
            { video: true, audio: true }
          ).then((stream) => {
            executeLogin(values)
          }).catch((err) => {
            // // Deactivate indicator
            submitButton.value?.removeAttribute('data-kt-indicator')
            //   // eslint-disable-next-line
            submitButton.value!.disabled = false
            Swal.fire({
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Okay',
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger'
              }
            })
          })
        }
        // Good to go!
      })
      // await store.dispatch(Actions.API_ACTIVE_USER_LOGIN, values)
      // const [errorName] = Object.keys(store.getters.getErrors)
      // const error = store.getters.getErrors[errorName]
      // if (!error) {
      //   // Go to page after successfully login
      //   await router.push({ name: 'console' })
      // } else {
      //   Swal.fire({
      //     html: error[0],
      //     icon: 'error',
      //     buttonsStyling: false,
      //     confirmButtonText: 'Okay',
      //     customClass: {
      //       confirmButton: 'btn fw-bold btn-light-danger'
      //     }
      //   })
      // }

      // // Deactivate indicator
      // submitButton.value?.removeAttribute('data-kt-indicator')
      //   // eslint-disable-next-line
      //   submitButton.value!.disabled = false;
    }

    const executeLogin = (values) => {
      store.dispatch(Actions.API_ACTIVE_USER_PUBLIC_LOGIN, values).then((response) => {
        values.accessCode = response.payload.activeUser.accessCode
        values.uid = response.payload.activeUser.activeUserUuid
        store.dispatch(Actions.API_ACTIVE_USER_LOGIN, values).then((response) => {
          const imagesForPreload = getTemplateImagesBySection(response.payload.template.sections[0], response.payload.activeUser.company)
          const tempImg = [] as any
          for (let x = 0; x < imagesForPreload.length; x++) {
            tempImg[x] = new Image()
            tempImg[x].src = imagesForPreload[x]
          }
          // push to router
          SessionStorage.deleteItem('clickedSection-template-' + response.payload.template.templateUuid)
          // requires customization
          if (response.payload.activeUser.preIntro === 1) {
            router.push({ name: 'customize-character' })
          } else {
            router.push({ name: 'curiosity-session' })
          }
          if (!SessionStorage.getItem('loggedInAt')) {
            SessionStorage.saveItem('loggedInAt', new Date())
          }
        }).catch((response) => {
          console.log('could not log in', response)
          // // Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator')
          //   // eslint-disable-next-line
          submitButton.value!.disabled = false

          Swal.fire({
            html: response.data.error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Okay',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
      }).catch((response) => {
        console.log('could not log in', response)
        submitButton.value?.removeAttribute('data-kt-indicator')
        //   // eslint-disable-next-line
        submitButton.value!.disabled = false
        Swal.fire({
          html: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Okay',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      })
    }

    onMounted(() => {
    })

    onUnmounted(() => {
    })

    const isLoginFading = ref(false)
    onBeforeRouteLeave((to, from, next) => {
      isLoginFading.value = true
      setTimeout(() => {
        next()
      }, 100)
    })
    return {
      login,
      submitButton,
      onSubmitLogin,
      buildApiResourceUrl,
      isLoginFading
    }
  }
})
